/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
    /* COLORS */

    /* Primary - Blue */
    --clr-primary       : #326bee;
    --clr-primary-light1: #326bee0d;
    --clr-primary-light2: #326bee08;
    --clr-primary-light3: #326bee1a;

    /* Primary Accent - Blue */
    --clr-primary-accent : #D2DFFF;
    --clr-primary-accent1: #F3F7FF;
    --clr-primary-accent2: #6d96ff;
    --clr-primary-accent3: #f0f5ff;
    --clr-primary-accent4: #f3f6fe;

    /* Secondary - Grey*/
    --clr-black           : #000000;
    --clr-secondary-dark  : #888888;
    --clr-seconadary      : #666666;
    --clr-secondary-light1: #66666699;
    --clr-secondary-light2: #66666666;
    --clr-secondary-light3: #6666660d;

    /* Light Background */
    --clr-white      : #ffffff;
    --clr-light-gray1: #f8f8f8;
    --clr-light-gray2: #F5F5F5;
    --clr-light-gray3: #e8e8e8;
    --clr-light-gray4: #d9d9d9;

    /* Borders */
    --clr-border1: #dfe3e7;
    --clr-border2: #cccccc;

    --clr-overlay: #66666680;

    /* Other Colors */
    --clr-orange-text: #f58020;
    --clr-red-star   : #e24a47;

    --clr-red            : #ff7878;
    --clr-red-bg         : #ffbfbf;
    --clr-red-bg-light   : #ffbfbfb2;
    --clr-green          : #008000b2;
    --clr-green-bg       : #b8e9b8;
    --clr-green-bg-light : #b8e9b8b2;
    --clr-violet         : #6355b9;
    --clr-violet-bg      : #abb3ff;
    --clr-violet-bg-light: #abb3ffb2;
    --clr-blue           : #1282be;
    --clr-blue-bg        : #a3dfff;
    --clr-blue-bg-light  : #a3dfffb2;
    --clr-grey           : #7a8592;
    --clr-grey-bg        : #e5e6eb;

    --fc-dark      : var(--clr-black);
    --fc-white     : var(--clr-white);
    --fc-light-dark: var(--clr-seconadary);
    --fc-primary   : var(--clr-primary);

    /* FONT SIZES */
    --fs-small   : 12px;
    --fs-medium  : 14px;
    --fs-large   : 16px;
    --fs-heading1: 18px;
    --fs-heading2: 20px;
    --fs-heading3: 22px;
    --fs-heading4: 24px;

    /* FONT WEIGHTS */
    --fw-regular  : 400;
    --fw-semi-bold: 500;
    --fw-bold     : 600;
}

* {
    margin : 0;
    padding: 0;
}

html::-webkit-scrollbar {
    display: none;
}

body {
    background : var(--clr-light-gray2);
    font-family: "Poppins", sans-serif;
    font-style : normal;
    font-weight: var(--fw-regular);
    font-size  : var(--fs-medium);
}

input {
    max-width    : 25vw !important;
    height       : 2.5rem !important;
    padding      : 1% !important;
    padding-left : 12px !important;
    border       : 1px solid var(--clr-secondary-light2);
    border-radius: 5px;
    font-family  : inherit;
    font-weight  : var(--fw-semi-bold);
    margin       : 1vh 0;
}

input::placeholder {
    font-family: inherit;
    color      : var(--clr-secondary-light2);
    font-size  : var(--fs-medium);
}

.hidden {
    display: none;
}

/* CUSTOM ELEMENTS */
.addIcon-plus {
    background-color: var(--clr-primary-light1);
    font-size       : var(--fs-small) !important;
    height          : 2.5rem;
    width           : 2.5rem;
    color           : var(--fc-primary);
    font-weight     : var(--fw-bold) !important;
    display         : inline-flex !important;
    align-items     : center;
    justify-content : center;
    border-radius   : 4px !important;
    cursor          : pointer;
    transition      : background-color 0.1s;
    margin          : 0 8px;
}

.addIcon-plus:hover {
    background-color: #326bee22;
}

.removeIcon-minus {
    background-color: var(--clr-light-gray1);
    font-size       : var(--fs-small) !important;
    height          : 2.5rem;
    width           : 2.5rem;
    color           : var(--clr-secondary-light1);
    font-weight     : var(--fw-bold) !important;
    display         : inline-flex !important;
    align-items     : center;
    justify-content : center;
    border-radius   : 4px !important;
    cursor          : pointer;
    transition      : background-color 0.1s;
    margin          : 0 8px;
}

.removeIcon-minus:hover {
    background-color: var(--clr-light-gray3);
}

.clearAndSave-buttons {
    display        : flex;
    justify-content: flex-end;
}

.clearAndSave-buttons>.p-button-outlined.clear {
    height: 40px;
    width : 7rem;
    color : var(--fc-light-dark) !important;
    border: 1px solid var(--clr-seconadary) !important;
    margin: 0 10px;
}

.clearAndSave-buttons>.p-button-outlined.save {
    background-color: var(--clr-primary);
    color           : var(--fc-white);
    border          : 1px solid var(--clr-primary);
    height          : 2.5rem;
    width           : 7rem;
    margin          : 0 10px;
}

.clearAndSave-buttons>.p-button.p-button-outlined.save:enabled:hover {
    background: #1158fa !important;
    color     : white !important;
}

.clearAndSave-buttons>.p-button.p-button-outlined.clear:enabled:hover {
    background: #6666661f !important;
}

input:-internal-autofill-selected {
    background-color: var(--clr-white) !important;
}

.header-btn>.p-button-raised {
    background-color: var(--clr-primary) !important;
    color           : var(--fc-white) !important;
}

em.info-button::before {
    content        : "i";
    width          : 16px;
    height         : 16px;
    margin         : 5px;
    border-radius  : 50%;
    border         : 1px solid var(--clr-primary);
    color          : var(--clr-primary);
    font-size      : var(--fs-medium);
    font-weight    : var(--fw-bold);
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    font-family    : serif;
}

.loadingOverlay {
    width           : 100%;
    height          : 100%;
    top             : 0;
    bottom          : 0;
    left            : 0;
    z-index         : 1000001;
    position        : fixed;
    background-color: rgba(0, 0, 0, 0.4);
}

span.imp {
    color: var(--clr-red-star);
}

.round-progress {
    position: static !important;
}


/* PRIME-NG INPUT */
.p-inputtext {
    width       : 12rem;
    font-family : inherit !important;
    font-weight : var(--fw-semi-bold);
    color       : var(--fc-dark) !important;
    margin      : 1vh 0 !important;
    padding-left: 15px !important;
}

.p-inputtext:focus {
    border : 1px solid var(--clr-secondary-light2) !important;
    outline: none !important;
}

.p-input-icon-left>em {
    position   : absolute;
    top        : 50%;
    margin-top : -0.5rem;
    font-size  : var(--fs-large);
    color      : var(--clr-secondary-light2);
    margin-left: 8px;
}

.p-inputgroup>input::placeholder {
    font-size: var(--fs-small);
    position : relative;
    left     : 0.5rem;
}

.p-inputtext.p-inputmask {
    width: 100% !important;
}

.p-inputnumber {
    width: 100% !important;
}

.eventForm-elements>input,
.eventForm-elements>.dropdown>.p-dropdown,
.slotForm-elements>.dropdown>.p-dropdown,
.p-inputgroup.eventForm-elements {
    width     : 25vw !important;
    min-height: 2.5rem !important;
}

/* PRIME-NG SIDEBAR */
.p-sidebar .p-sidebar-header {
    background-color: var(--clr-white);
    height          : 0px;
    padding         : 0 !important;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon::before {
    background-color: var(--clr-light-gray1) !important;
    border-radius   : 3px !important;
    margin          : 3rem 1rem 0 !important;
    z-index         : 10;
    height          : 20px !important;
    width           : 20px !important;
}

.p-sidebar .p-sidebar-content {
    padding: 0px 5px !important;
    height : 100%;
}

.sidebar-saveSettings>.p-inputgroup>button>.p-button-label {
    font-size: var(--fs-small);
}

.p-sidebar-left {
    top   : 11.8rem !important;
    left  : 80px !important;
    width : 20.2rem !important;
    height: 72vh !important;
}

.p-sidebar-right>.p-sidebar-header>.p-sidebar-close {
    position       : absolute;
    left           : -40px;
    margin         : 2rem 0 0 !important;
    background     : var(--clr-seconadary) !important;
    color          : var(--fc-white) !important;
    height         : 40px !important;
    width          : 40px !important;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
}

.p-sidebar-content::-webkit-scrollbar {
    display: none !important;
}

.candidateSidebar>.p-sidebar-left {
    position: sticky !important;
    height  : 66vh !important;
    width   : 20vw !important;
    top     : 0px !important;
}

/* PRIME-NG INPLACE */
.p-inplace.p-component.p-inplace-closable {
    width      : 30vw;
    height     : 3rem;
    display    : inline-flex;
    align-items: center;
}

.p-inplace .p-inplace-display {
    display    : block !important;
    width      : fit-content;
    font-size  : var(--fs-small);
    font-weight: var(--fw-semi-bold);
    color      : var(--fc-light-dark);
    padding    : 0.5rem;
}

.p-inplace .p-inplace-content {
    width: inherit;
}

.p-inplace-content>.p-button.p-button-icon-only {
    height       : 2rem;
    width        : 2rem;
    border-radius: 0 4px 4px 0;
    margin       : auto 0 !important;
}

.p-inplace-content>.ng-star-inserted {
    height       : 2rem !important;
    border-radius: 4px 0 0 4px;
    margin       : auto 0 !important;
}

.addCategory .p-inplace .p-inplace-display {
    border         : var(--clr-primary) 1px solid;
    color          : var(--fc-primary);
    width          : 100%;
    height         : 2.5rem;
    display        : inline-flex !important;
    justify-content: center;
    align-items    : center;
}

.addCategory>.p-inplace>.p-inplace-content>.newCategory {
    margin: 0.5rem auto !important;
    width : 99%;
}

/* PRIME-NG CALENDAR */
.p-calendar-w-btn .p-datepicker-trigger {
    height: 40px;
    right : 40px;
}

.cohort-id input,
.cohort-SPOC .p-inputwrapper .p-dropdown .p-inputtext,
.cohort-calendar .p-calendar-w-btn .p-inputtext {
    min-width       : 10vw !important;
    width           : min-content !important;
    background-color: #ECECEC;
    border          : none;
    height          : 40px;
    border-radius   : 2px !important;
}

.cohort-SPOC .p-inputwrapper .p-dropdown .p-dropdown-trigger {
    background-color: #ECECEC;
    height          : 40px;
}

.cohort-calendar .p-calendar-w-btn .p-inputtext {
    cursor: pointer;
}

.cohort-SPOC .p-inputwrapper .p-dropdown {
    background-color: #ECECEC;
    border          : none;
    border-radius   : 2px;
}

.p-calendar {
    align-items: center;
}

.p-calendar-w-btn .p-inputtext {
    border-radius: 6px !important;
}

.p-datepicker-trigger.p-button {
    background  : none !important;
    color       : var(--clr-black) !important;
    border-left : none !important;
    border-color: transparent !important;
}

/* PRIME-NG ACCORDION */
.p-accordion .p-accordion-content {
    border : none !important;
    padding: 0.5rem 0.5rem 1rem !important;
}

.p-accordion label {
    padding-left: 10px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    color          : var(--fc-primary) !important;
    background     : var(--clr-primary-light1) !important;
    padding        : 12px !important;
    flex-direction : row-reverse;
    justify-content: space-between;
}

/* PRIME-NG FLOATLABEL */
.p-float-label>label {
    left               : 0.75rem;
    color              : var(--fc-light-dark);
    transition-duration: 0.2s;
    font-size          : var(--fs-medium);
    font-weight        : var(--fw-semi-bold);
}

.p-float-label input.p-filled~label,
.p-float-label input:focus~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
    top             : 1vh !important;
    color           : var(--fc-primary) !important;
    background-color: var(--clr-white);
    font-size       : 11px !important;
    font-weight     : var(--fw-semi-bold);
    padding         : 3px;
}

.p-float-label>.p-dropdown {
    width: 12rem !important;
}

/* PRIME-NG DROPDOWN */
.p-dropdown {
    width        : 12rem;
    height       : 2.5rem;
    margin       : 1vh 1vw 1vh 0;
    border       : 1px solid var(--clr-border1);
    border-radius: 5px;
    align-items  : center;
}

.p-dropdown.p-focus {
    box-shadow: none !important;
}

.p-dropdown .p-dropdown-trigger>.pi-chevron-down {
    font-size: var(--fs-small);
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0 !important;
}

.userForm-elements>.p-element>.p-dropdown,
.templateForm-elements>.p-element>.p-dropdown {
    width: 32vw;
}

.emailForm-elements .p-inputwrapper .p-dropdown,
.offerForm-elements .p-inputwrapper .p-dropdown{
    width: 25vw !important;
}

/* PRIME-NG SPLITBUTTON */
.p-splitbutton.p-button-sm>.p-button {
    padding: 0.5rem 0.6rem !important;
}

.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton.p-button {
    border-left: 0 none !important;
}

.p-tieredmenu .p-menuitem-text {
    line-height: 0 !important;
    font-size  : var(--fs-medium);
    font-weight: lighter;
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border    : 0 none;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    width     : 176px;
}

.p-tieredmenu .p-menuitem {
    width: auto;

}

.p-menuitem-text {
    margin     : 2vh 1vw;
    font-weight: var(--fw-bold);
    font-size  : var(--fs-heading1);
}

/* PRIME-NG PANEL */
.p-panel.p-panel-toggleable .p-panel-header {
    background-color: var(--clr-white);
    font-weight     : var(--fw-semi-bold);
    color           : var(--fc-dark);
    border-radius   : 2px;
    border          : 1px solid #dfe3e7;
    height          : 3.125rem;
}

.p-panel.p-panel-toggleable .p-panel-header:hover {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

.p-panel .p-panel-header .p-panel-header-icon {
    border-radius   : 5px !important;
    margin-left     : 8px;
    background-color: var(--clr-secondary-light3) !important;
    color           : var(--clr-seconadary) !important;
}

.p-panel .p-panel-header .p-panel-header-icon:hover {
    background-color: #66666622 !important;
}

.p-panel-titlebar-toggler {
    background-color: var(--clr-primary) !important;
}

.p-ripple.p-panel-header-icon.p-panel-toggler {
    display: none !important;
}

/* PRIME-NG DIALOG */
.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-header {
    background: #6666660d;
    height    : 50px;
    padding   : 10px 20px;
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-content {
    padding: 0.5rem 1rem;
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-header>span {
    font-size: 14px;
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog {
    border-radius: 5px;
    box-shadow   : 0px 2px 14px rgba(0, 0, 0, 0.06);
    background   : var(--clr-white);
    border       : 1px solid var(--clr-border1);
    padding      : 1px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    border-radius: 2px !important;
    width        : 1.5rem !important;
    height       : 1.5rem !important;
}

.p-dialog .p-dialog-footer button{
    height: 40px !important;
}

/* PRIME-NG CHIP */
.p-chip {
    background-color: var(--clr-white) !important;
    border          : 1px solid var(--clr-border2);
    border-radius   : 3px !important;
    padding         : 0 !important;
    margin          : 7px 5px;
    cursor          : pointer;
    transition      : background-color 0.2s;
}

.p-chip:hover {
    background-color: var(--clr-secondary-light3) !important;
}

.p-chip .p-chip-text {
    font-size: 12px !important;
    margin   : 4px 15px !important;
}

/* PRIME-NG CHIPS */
.p-chips-multiple-container {
    width         : 25vw;
    min-height    : 2.5rem;
    padding-bottom: 8px !important;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding      : 5px;
    font-size    : 12px;
    background   : #f8f8f8 !important;
    color        : #000000 !important;
    border       : 1px solid #dfe3e7 !important;
    margin       : 8px 5px 0 !important;
    border-radius: 3px;
    max-width    : 24vw;
}

.p-chips-input-token input {
    display: none;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding      : 5px;
    font-size    : 12px;
    background   : #f8f8f8 !important;
    color        : #000000 !important;
    border       : 1px solid #dfe3e7 !important;
    margin       : 8px 5px 0 !important;
    border-radius: 3px;
    max-width    : 24vw;
}

.p-multiselect {
    width     : 25vw;
    min-height: 2.5rem;
    margin    : 1vh 0;
}

.p-multiselect-label {
    white-space: unset !important;
    cursor     : pointer;
    overflow   : auto;
}

.p-multiselect .p-multiselect-trigger {
    width: 1.5rem !important;
}

.p-multiselect-trigger-icon.pi {
    font-size: 12px;
}

.p-multiselect-filter-container .p-inputtext {
    height: 2rem !important;
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.25rem 1rem !important;
}

/* PRIME_NG BADGE */
.remainingItems-badge>p-badge>.p-badge {
    background     : #326bee0d;
    color          : #326bee;
    font-size      : 12px !important;
    font-weight    : 400;
    display        : inline-flex;
    border-radius  : 50px;
    justify-content: center;
    align-items    : center;
    padding        : 0;
    margin         : 5px;
    cursor         : pointer;
}

/* PRIME-NG TIMELINE */
.p-timeline-event-marker {
    border          : 2px solid #008000 !important;
    background-color: #008000 !important;
    margin-top      : 5px;
}

.p-timeline-event-opposite {
    display: none !important;
}

/* PRIME-NG COMMON */
button:focus,
button:active,
.p-checkbox-box.p-focus,
input:focus,
input:active p-dropdown:focus,
p-dropdown:active,
.p-accordion .p-accordion-header .p-accordion-header-link:focus {
    box-shadow: none !important;
    outline   : none !important;
}

.ql-editor>* {
    font-family: "Poppins", sans-serif;
}

.p-component {
    font-family: "Poppins", sans-serif !important;
    font-style : normal !important;
    font-size  : var(--fs-medium) !important;
}

.pi-sort-alt {
    display: none !important;
    color  : var(--fc-light-dark) !important;
}

.p-datatable-scrollable .p-datatable-tbody>tr {
    min-height: 54px;
}

.p-overlaypanel .p-overlaypanel-content {
    padding       : 0 !important;
    display       : flex;
    flex-direction: column;
}

.p-button.p-button-raised {
    box-shadow: none !important;
}

.table-heading>th:hover .pi-sort-alt {
    display: block !important;
}

.p-skeleton {
    background-color: #eaecee !important;
    border-radius   : 4px !important;
}

ngx-dropzone-remove-badge {
    background-color: var(--clr-red-star) !important;
}

.profile-preview>img {
    max-width : 35% !important;
    max-height: 70% !important;
    position  : relative;
    top       : -10px;
}

.fileUpload-dropzone>input {
    position: static !important;
}

.addUsers-button>.p-splitbutton>.p-button,
.addCandidates-button>.p-splitbutton>.p-button {
    background : var(--clr-primary);
    border     : none;
    border-left: solid 1px var(--clr-white);
    height     : 2.4rem;
}

.addUsers-button>.p-splitbutton>.p-button:hover,
.addCandidates-button>.p-splitbutton>.p-button:hover {
    border     : none;
    border-left: solid 1px var(--clr-white);
}

.p-checkbox-icon.pi-check:before {
    font-size  : 10px;
    font-weight: var(--fw-bold);
    line-height: 4;
}

.p-calendar .p-inputtext {
    width: 28vw !important;
}

.p-progress-spinner {
    position: relative;
    top     : 50%;
    left    : 50%;
}

.p-progress-spinner-circle {
    stroke : var(--clr-black) !important;
    opacity: 1 !important;
}

.p-datatable-tbody>tr:hover {
    background-color: var(--clr-primary-accent4) !important;
    color           : var(--fc-primary) !important;
    border          : 2px 0 solid var(--clr-white);
    box-shadow      : 0px 2px 4px rgb(0 0 0 / 20%);
    border-radius   : 4px;
}

.p-overlaypanel {
    max-height: 35vh;
    overflow  : scroll;
}

.p-overlaypanel::-webkit-scrollbar {
    display: none !important;
}

.custom-bread-crumb .line {
    padding: 0 8px !important;
}

.custom-bread-crumb li a:hover {
    text-decoration: none !important;
}

.custom-bread-crumb li span {
    font-weight: 600;
}

.customJobDescription>h1,
.customJobDescription>h2,
.customJobDescription>h3,
.customJobDescription>h4,
.customJobDescription>h5,
.customJobDescription>h6 {
    color: var(--clr-black);
}

.customJobDescription>ul {
    margin-left: 20px;
}

.custom-bread-crumb {
    width        : 48vw;
    white-space  : nowrap;
    text-overflow: ellipsis;
    overflow-x   : hidden;
}

.custom-bread-crumb:hover {
    overflow-x   : scroll;
    text-overflow: clip;
}

.custom-bread-crumb::-webkit-scrollbar {
    display: none;
}

.ruleSet-trigger>.p-dropdown {
    min-width: 12rem;
    width    : fit-content;
}


.selection-operations-options>.p-button>.p-button-icon {
    font-size  : 12px !important;
    font-weight: 600 !important;
    color      : black !important;
}

.selection-operations-options>.p-button>.p-button-label {
    font-weight: 400;
    color      : var(--clr-seconadary);
}

.avatar-align>.p-avatar-image>img {
    border-radius: 50%;
}


/* .p-datatable-scrollable t.p-frozen-column {
    z-index: 5 !important;
}

.p-datatable-scrollable th.p-frozen-column {
    z-index: 10 !important;
}

.p-datatable .p-datatable-loading-overlay {
    z-index: 15 !important;
} */

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    display: none;
}

.editStatus>.p-inputwrapper>.p-dropdown {
    position: absolute;
    width   : auto;
    margin  : -20px -8px;
    height  : 2rem;
}

.editStatus>.p-inputwrapper>.p-dropdown>.p-dropdown-label {
    font-size: 12px;
}

.addon-input.salary>.p-inputnumber>.p-inputtext {
    border-radius: 0 4px 4px 0;
}

.addon-input>.p-inputnumber>.p-inputtext {
    border-radius: 4px 0 0 4px;
}

span.template-content>p>img {
    max-width: -webkit-fill-available !important;
}

.acc-phNo>.p-inputtext {
    max-width: 32vw !important;
}

.feedBackCell>.simple-btn>.p-button-icon {
    font-size: 14px !important;
}

.p-tooltip>.p-tooltip-text{
    font-size: 12px !important;
    padding: 0.5rem 0.5rem !important;
}

.file-preview>img{
    display: none !important;
}

.p-divider.p-divider-horizontal {
    border-top: 2px #dee2e6 !important;
}

.submit-btn .p-element .p-button{
    width: 100% !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
}

.submit-btn .p-element .p-button .p-button-label{
    flex: none;
}

.submit-btn .p-element.disabled .p-button{
    background-color: #888888;
    border-color: #888888;
    pointer-events: none;

}

.rename-status .editStatus-elements .p-inputText{
    width: 12vw;
}

.editStatus-elements .p-inputwrapper .p-dropdown{
    width: 30vw;
}

.editStatus-elements .p-inputtext{
    width: 13.5vw;
}

@media (max-width: 1370px) {
    .p-dropdown {
        width: 12vw;
    }

    input.conditionSet-value {
        width: 12vw;
    }
}

.tox {
    font-family: "Poppins", sans-serif !important;
    margin     : 7px 0px;
}

.tox-tinymce {
    border-radius: 10px !important;
}

.otp-type>div>input {
    border: 1px solid var(--clr-primary) !important;
}

ng-otp-input>div>input {
    height: 50px !important;
    width : 50px !important;
}

.tox-editor-header {
    position: absolute !important;
    top     : 0px !important;
    left    : 0px !important;
}

.tox-editor-dock-fadeout {
    opacity   : 1 !important;
    visibility: visible !important;
}

.p-inputtext.ng-invalid+label.touched-email {
    color: var(--clr-red-star) !important;
}

.p-inputtext.ng-invalid+label.touched-password {
    color: var(--clr-red-star) !important;
}

.p-inputtext.dirty-input+label.invalid-credentials {
    color: var(--clr-red-star) !important;
}

.acc-phNo > .p-inputnumber > .p-inputnumber-input{
    max-width: 32vw !important
}

.signin-spinner>.p-progress-spinner {
    top    : 0px !important;
    left   : 0px !important;
    display: flex;
}

.sign-btn-load>.p-element>.p-progress-spinner>.p-progress-spinner-svg>.p-progress-spinner-circle {
    stroke: var(--clr-white) !important;
}

.tox-editor-container{
    padding-top: 120px !important;
}

#associated-role_dropdown > .p-dropdown {
    margin-right: 0px !important;
}

.dropdown-input_condition > .p-dropdown > .p-dropdown-trigger
{
    display: none;
}


/* WileyForm */
.wiley-form>.dropdown>.field-dropdown>.p-dropdown {
    width: 100% !important;
}

.wiley-form>span>input {
    width: 100%;
}

.ng-trigger-overlayAnimation{
        left: 0px !important;
        max-width: 50vw !important;
}

@media screen and (max-width: 1220px) and  (min-width: 980px) {
    input {
        max-width: 32vw !important;
    }

    .p-calendar .p-inputtext {
        width: 32vw !important;
    }

}
@media screen and (max-width: 980px) and (min-width: 720px)  {
    input {
        max-width: 40vw !important;
    }

    .p-calendar .p-inputtext {
        width: 40vw !important;
    }

    .wiley-form>.dropdown>.field-dropdown>.p-dropdown{
        width: 80vw !important;
    }
}

@media screen and (max-width: 720px) and (min-width: 480px){
    input {
        max-width: 80vw !important;
    }

    .p-calendar .p-inputtext {
        width: 55vw !important;
    }

    .wiley-form>.dropdown>.field-dropdown>.p-dropdown{
        width: 80vw !important;
    }
}

@media screen and (max-width: 480px) {
    input {
        max-width: 80vw !important;
    }

    .p-calendar .p-inputtext {
        width: 80vw !important;
    }

    .wiley-form>.dropdown>.field-dropdown>.p-dropdown{
        width: 80vw !important;
    }
}   

.cohortDropdown .p-dropdown-panel.p-component {
    width: 100%;
}